<template>
  <b-card 
    class="preview-card p-1"
    style="width: 450px; height: 525px;"
  >
    <p class="preview-card-title text-light">
      {{ $t('integrator.pages.create_service.preview') }}
    </p>
    <b-card 
      class="border rounded"
      no-body
    >
      <b-container fluid class="p-0">
            <div style="height: 164px;" class="bg-dark-blue rounded">
            </div>
          <b-row>
          <b-col class="position-absolute m-0 p-0 preview-card-avatar">
            <b class="preview-card-avatar-change rounded">
              <feather-icon
                icon="EditIcon"
                size="18"
              />
            </b>
            <b-avatar size="95px" class="border" rounded>
              <!-- card.avatar_img.img -->
              <b-img
                :src="avatar_img || 'https://media.giphy.com/avatars/FiqOn/NQ35V5531uDE.png'"
                alt="" 
                class="preview-card-avatar-img"
                fluid
                center
                size="contain"
              />
            </b-avatar>
            <b-form-file
              class="position-absolute preview-card-input-file-avatar"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept="image/png, image/gif, image/jpeg"
              @change="(event) => onFileChange(event, 'avatar_img')"
            >
            </b-form-file>
          </b-col>
        </b-row>
        
      
        <b-row class="px-2 mt-3">
          <b-col cols="12" class="mb-50 pl-1">
            <p class="preview-card-category mb-0">
              {{ card.category ? card.category.label : $t('integrator.pages.create_service.category') }}
            </p>
          </b-col>
          <b-col cols="12">
            <p class="preview-card-name">
              {{ card.name || $t('integrator.pages.create_service.service_name') }}
            </p>
          </b-col>
          <b-col cols="12">
            <p class="preview-card-description">
              {{ limitDescriptionDisplayLenght || mockText }}
            </p>
          </b-col>
        </b-row>
        <hr class="w-100">
        <b-row class="mb-0">
          <b-col>
            <p class="cursor-pointer preview-card-details ml-2">
              {{ $t('integrator.pages.create_service.see_details') }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </b-card>
</template>

<script>
import { 
  BCard,
  BCardImg,
  BContainer,
  BRow,
  BCol,
  BAvatar,
  BOverlay,
  BFormFile,
  BImg,
} from 'bootstrap-vue'
  export default {
    components: {
      BCard,
      BCardImg,
      BContainer,
      BRow,
      BCol,
      BAvatar,
      BOverlay,
      BFormFile,
      BImg,
    },
    props: {
      value: {
        type: Object,
        default: () => {}
      },
    },
    data() {
      return {
        mockText: "Jujubes apple pie lemon drops candy canes fruitcake ice cream jelly. Toffee fruitcake dragée lollipop bonbon chocolate bar.",
        descMaxLength: 100,
        limitedDescription: '',
        cover_img: null,
        avatar_img: null,
      }
    },
    watch: {
      headerFile() {
        console.log('header file', this.headerFile);
      }
    },
    computed: {
      limitDescriptionDisplayLenght() {
        var string = this.value.short_description;
        return `${string.substring(0, this.descMaxLength)}${string.length > 300 ? '...' : ''}`;
      },
      card: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    methods: {
      onFileChange(e, target) {

        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        var reader = new FileReader();
          
        reader.onload = (event) => {
          this[target] = event.target.result
         
          let base64 = reader.result
          base64 = base64.split(',')[1]
          this.card[target] = base64
        };
        reader.readAsDataURL(files[0]);
      },
    }
  }
</script>

<style lang="scss">
  .preview-card-cover {
    .custom-file-label {
      cursor: pointer;
      height: 164px;
    }

    .form-file-text {
      opacity: 0;
      height: 164px;
    }

    &:hover {
      .preview-card-cover-change {
        cursor: pointer;
        opacity: 1;
        height: 164px;
      }
    }

    .preview-card-cover-change {
      display: block;
      position: absolute;
      height: 164px;
      width: 100%;
      background: #000000d6;
      line-height: 164px;
      text-align: center;
      font-size: 17px;
      opacity: 0;

      transition: opacity 0.13s ease;
    }
  }

  .preview-card-avatar {
    left: 20px; 
    top: 90px;
    height: 95px;
    width: 95px;
    .custom-file-label {
      cursor: pointer !important;
      height: 95px !important;
    }

    .form-file-text {
      opacity: 0;
      height: 95px;
    }

    &:hover {
      .preview-card-avatar-change {
        height: 95px;
        cursor: pointer;
        opacity: 1;
      }
    }

    .preview-card-avatar-change {
      // display: block;
      position: absolute;
      height: 95px;
      width: 95px;
      background: #000000b4;
      line-height: 95px;
      text-align: center;
      opacity: 0;
      z-index: 6;
      transition: opacity 0.13s ease;
    }
  }
</style>

<style lang="scss" scoped>

.preview-card {
  position: sticky;
  top: 30px;  
  .preview-card-input-file-avatar {
    opacity: 0;
    width: 95px;
    left: 0;
    z-index: 6 !important;
    top: 0;
    height: 95px;
  }
  
  .preview-card-avatar-img {
    // opacity: 1;
    // &:hover {
    //   opacity: 0.3; 
    //   cursor: pointer; 
    //   z-index: 1;
    // }
  }
  
  .preview-card-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }  
  .preview-card-category {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6E6B7B;
  }
  .preview-card-name {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #D0D2D6;
  }
  .preview-card-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #B4B7BD;
  }
  .preview-card-details {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.4px;
    color: #7367F0;

  }
}

</style>